<template>
  <div class="height1">
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="Search"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="专家名称" @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div style="margin-top: -20px"><el-divider></el-divider></div>
        <div style="height: calc(100% - 120px); overflow-y: auto; overflow-x: hidden">
          <el-row :gutter="20">
            <el-col :span="6" class="col" v-for="(item, i) in tableData" :key="i">
              <div class="note">
                <div class="top-1 flex-start" style="min-height: 51px">
                  <img class="note-logo" :src="`${CommonPath}${item.photoFile}`" v-if="item.photoFile" />
                  <img class="note-logo" src="@/assets/img/touxiang04.png" v-else />
                  <div>
                    <div>
                      <span class="note-name">{{ item.userName }}</span
                      ><span>{{ item.userTitleName }}</span>
                    </div>
                    <div class="flex-start" v-if="item.workUnit">
                      <div class="note-hos">{{ item.workUnit }}</div>
                      <div class="hos-desc flex-center">
                        <img class="note-l" src="@/assets/img/n1.png" /><span>{{ item.levelName }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="note-2">
                  <div class="twoLine note-desc">
                    擅长：{{ item.userExpertiseTags.join('、') }}
                    <div class="desc" @click="infoBtn(item)">详情<i class="el-icon-arrow-right"></i></div>
                  </div>
                </div>
                <div class="note-3 flex-space-between">
                  <div class="flex-start">
                    <img class="note3-img" src="@/assets/img/n4.png" />
                    <p class="note3-shenfang">审方费：￥{{ item.auditDiagnoseMoney }}/单</p>
                  </div>
                  <p>开方分成比例：{{ item.mzRate ? item.mzRate : 0 }}%</p>
                </div>
                <div class="note-4 flex-space-between">
                  <div class="flex-start">
                    <img src="@/assets/img/n3.png" />
                    <p class="note4-logo">可预约</p>
                  </div>
                  <div class="flex-start">
                    <el-button type="primary" size="mini" plain @click="infoBtn(item)">详情</el-button>
                    <el-button type="primary" size="mini" @click="reservation(item)">预约</el-button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="专家预约申请" :visible.sync="reservationDialog" class="el-dialog-box">
      <div class="dialog-c">
        <div class="top-1 flex-start">
          <img class="note-logo" :src="`${CommonPath}${reservationInfo.photoFile}`" v-if="reservationInfo.photoFile" />
          <img class="note-logo" src="@/assets/img/touxiang04.png" v-else />
          <div>
            <div>
              <span class="note-name">{{ reservationInfo.userName }}</span
              ><span>{{ reservationInfo.userTitleName }}</span>
            </div>
            <div class="flex-start" v-if="reservationInfo.workUnit">
              <div class="note-hos">{{ reservationInfo.workUnit }}</div>
              <div class="hos-desc flex-center">
                <img class="note-l" src="@/assets/img/n1.png" /><span>{{ reservationInfo.levelName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="note-3 flex-space-between" style="margin-top: 10px">
          <div class="flex-start">
            <img class="note3-img" src="@/assets/img/n4.png" />
            <p class="note3-shenfang">审方费：￥{{ reservationInfo.auditDiagnoseMoney }}/单</p>
          </div>
          <p>开方分成比例：{{ reservationInfo.mzRate ? reservationInfo.mzRate : 0 }}%</p>
        </div>
      </div>
      <div class="custom-calendar">
        <div class="calendar-wrapper">
          <div class="calendar-header">
            <div class="calendar-current-date">
              {{ `${currYear}年${currMonth}月` }}
            </div>
            <div class="calendar-month-control">
              <el-button type="primary" size="mini" @click="handleMonthChange(-1)">上一月</el-button>
              <el-button type="primary" size="mini" @click="handleMonthChange(1)">下一月</el-button>
            </div>
          </div>
          <div class="calendar-week">
            <div class="calendar-week-list">
              <div v-for="(item, index) of weekly" :key="index" class="calendar-week-list__item">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="calendar-date-wrapper">
            <div class="calendar-date-list">
              <div
                :class="{
                  'calendar-date-list__item': true,
                  'is-current-month': item.currMonth,
                  'is-current-day': item.currDay,
                  'is-actived': item.actived,
                  disabled: item.disabled,
                  isMe: item.status == 1,
                  isOther: item.status == 2,
                }"
                v-for="(item, index) of dateList"
                :key="index"
                @click="handleActived(index, item.date)"
              >
                <div class="flex-center-column">
                  <p style="font-size: 20px; font-weight: bold">{{ item.day }}</p>
                  <p class="lunar">{{ item.lunar.lunarFestival ? item.lunar.lunarFestival : item.lunar.isTerm ? item.lunar.Term : item.lunar.IDayCn }}</p>
                </div>
                <template v-if="showStatusLabel">
                  <!-- <div class="date-status text-r" v-if="!item.disabled">
                    {{ statusArr[+!item.disabled].label }}
                  </div> -->
                  <div class="date-status text-r" v-if="item.disabled && item.status" :class="item.status == 2 ? 'isOtherT' : 'isMeT'">
                    {{ statusArr[2].label }}
                  </div>
                  <div style="clear: both"></div>
                </template>
              </div>
            </div>
          </div>
          <div class="calendar-footer">
            <el-button @click="handleResetCalendar">重置</el-button>
            <el-button type="primary" @click="handleConfirmCalendar">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-drawer class="box" title="详情" :visible.sync="detaildrawer" :direction="direction" wrapperClosable>
      <div class="drawer-box">
        <div class="noteInfo">
          <div class="top-1 flex-start">
            <img class="note-logo" :src="`${CommonPath}${reservationInfo.photoFile}`" v-if="reservationInfo.photoFile" />
            <img class="note-logo" src="@/assets/img/touxiang04.png" v-else />
            <div>
              <div>
                <span class="note-name">{{ reservationInfo.userName }}</span
                ><span>{{ reservationInfo.userTitleName }}</span>
              </div>
              <div class="flex-start" v-if="reservationInfo.workUnit">
                <div class="note-hos">{{ reservationInfo.workUnit }}</div>
                <div class="hos-desc flex-center">
                  <img class="note-l" src="@/assets/img/n1.png" /><span>{{ reservationInfo.levelName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="note-2" v-if="reservationInfo.userExpertiseTags">
            <div class="note-desc">
              <span style="color: #2174fd; line-height: 35px"><span style="font-size: 20px; font-weight: bold; line-height: 20px">· </span>擅长</span>
              <span style="line-height: 20px; text-indent: 0ch"> {{ reservationInfo.userExpertiseTags.join('、') }}。 </span>
            </div>
          </div>
          <div class="note-2" v-if="reservationInfo.abstract">
            <div class="note-desc">
              <span style="color: #2174fd; line-height: 35px"><span style="font-size: 20px; font-weight: bold; line-height: 20px">· </span>个人介绍</span>
              <span style="line-height: 20px; text-indent: 0ch" v-html="`${reservationInfo.abstract}`"></span>
            </div>
          </div>
          <div class="note-2" v-if="reservationInfo.honorDescription">
            <div class="note-desc">
              <span style="color: #2174fd; line-height: 35px"><span style="font-size: 20px; font-weight: bold; line-height: 20px">· </span>个人荣誉</span>
              <span style="line-height: 20px; text-indent: 0ch" v-html="`${reservationInfo.honorDescription}`"></span>
            </div>
          </div>
          <div class="note-3 flex-start">
            <div class="flex-start">
              <img class="note3-img" src="@/assets/img/n4.png" />
              <p class="note3-shenfang">审方费：￥{{ reservationInfo.auditDiagnoseMoney }}/单</p>
            </div>
            <p>开方分成比例：{{ reservationInfo.mzRate ? reservationInfo.mzRate : 0 }}%</p>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { ExpertAppointment } from '../../../components/HospitalDomain/ExpertAppointment'
import { User } from '../../../components/HospitalDomain/User'
import calendarFormatter from '../../../utils/calendarFormatter.js'
import { TimeFormatDate } from '../../../utils/extend'
export default {
  inject: ['reload'],
  name: '',
  components: {
    bread,
  },
  props: {
    data: Object, // 已选日期
    maxDays: {
      type: Number,
      default: -1, // 最多可选n天内, -1 表示不限制
    },
    showResetButton: {
      // 显示重置按钮
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      // 显示确定按钮
      type: Boolean,
      default: true,
    },
    showStatusLabel: {
      // 显示状态文字
      type: Boolean,
      default: true,
    },
  },
  data() {
    var user = new User(this.TokenClient, this.Services.Authorization)
    var expertAppointment = new ExpertAppointment(this.TokenClient, this.Services.Authorization)
    return {
      ExpertAppointment: expertAppointment,
      User: user,
      tableData: [],
      reservationInfo: {},
      detaildrawer: false,
      direction: 'rtl',
      selectedValues: [], // 已选中的值
      userData: [],
      searchForm: {
        keyWord: '',
        type: '全部',
        state: '-1',
        datepicker: '',
      },
      keyword: '',
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      dataTotal: 0,
      formData: {
        id: 0,
        name: '',
        remark: '',
        malfunctionMessage: '',
      },
      reservationDialog: false,
      rules: {
        malfunctionMessage: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        name: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
      },
      userId: JSON.parse(sessionStorage.getItem('userInfo')).id,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      formLabelWidth: '120px',
      weekly: ['日', '一', '二', '三', '四', '五', '六'], // 星期
      dateList: [], // 当前日期集合
      length: 42, // 日期框长度
      currYear: null, // 当前年份
      currMonth: null, // 当前月份
      currDate: null, // 当天日期
      activedDate: {}, // 已选日期
      statusArr: [
        // 状态
        { label: '不可预定', value: 0 },
        { label: '可预定', value: 1 },
        { label: '已预约', value: 1 },
      ],
      disabledDateList: [
        {
          date: '2025-01-01',
          status: true,
        },
        {
          date: '2025-01-03',
          status: false,
        },
      ], // 禁用日期
    }
  },
  watch: {
    data: {
      immediate: false,
      handler: function (val, oldVal) {
        if (val) {
          this.activedDate = { ...val }
        }
      },
    },
  },
  computed: {
    getDiffDate() {
      if (this.maxDays === null || +this.maxDays === -1) return false
      const date = new Date(this.currDate)
      date.setDate(date.getDate() + this.maxDays - 1)
      date.setHours(0, 0, 0, 0)
      return +date
    },
  },
  created() {
    // this.initCalendar()
  },
  mounted() {
    this.GetList()
  },
  methods: {
    getExpertAppointmentDetail(item) {
      var _this = this
      return new Promise((resolve, reject) => {
        _this.ExpertAppointment.GetExpertAppointmentDetail(
          1,
          item.id,
          function (data) {
            resolve(data.data)
          },
          function (error) {
            console.log(error)
            resolve(false)
          }
        )
      })
    },
    async reservation(item) {
      this.reservationInfo = item
      let res = await this.getExpertAppointmentDetail(item)
      this.disabledDateList = []
      if (res.length > 0) {
        res.forEach((i) => {
          let date = TimeFormatDate(i.appointmentDate * 1000)
          let map = {
            date: date,
            status: i.status,
          }
          this.disabledDateList.push(map)
        })
      }
      this.initCalendar()
      this.reservationDialog = true
    },
    // 初始化日历
    initCalendar() {
      this.currDate = this.dateFormatter(new Date(), 'YYYY-MM-DD')
      this.handleMonthChange()
    },
    // 月份切换
    handleMonthChange(diff = 0) {
      let date = new Date()
      if (diff !== 0) {
        date = new Date(`${this.currYear}/${this.currMonth}`)
        date.setMonth(date.getMonth() + diff)
      }
      this.currYear = date.getFullYear()
      this.currMonth = date.getMonth() + 1
      this.initDateList(this.currYear, this.currMonth - 1)
    },
    // 根据指定年、月份生成日期数组
    initDateList(year, month) {
      const firstDate = new Date(year, month, 1) // 当前月第一天
      const firstDayWeek = firstDate.getDay() // 当前月第一天为星期几
      const lastDate = new Date(year, month + 1, 0) // 当前月最后一天
      const lastDayWeek = lastDate.getDay() // 当前月最后一天为星期几
      const maxDay = lastDate.getDate() // 当前月最大天数

      /*
       * 下面分步获取日历的方式也可改为，先算出日历面板上第一个日期的节点，然后在逐步遍历 this.length 次
       */
      // 当月日期
      let dateList = this.getDateList(firstDate, maxDay, true)

      // 补充上月部分日期
      firstDate.setDate(-firstDayWeek + 1)
      dateList = [...this.getDateList(firstDate, firstDayWeek), ...dateList]

      // 补充下月部分日期
      lastDate.setDate(maxDay + 1)
      dateList = [...dateList, ...this.getDateList(lastDate, this.length - dateList.length)]
      this.dateList = dateList
    },
    // 获取日期数组
    getDateList(date, maxDay, currMonth = false) {
      const dateList = []
      for (let i = 0; i < maxDay; i++) {
        if (i > 0) date.setDate(date.getDate() + 1)
        const dateDay = this.dateFormatter(date, 'YYYY-MM-DD')
        const activatedDay = this.activedDate[dateDay]
        let disabledDates = this.disabledDateList.filter((i) => dateDay.indexOf(i.date) > -1)
        if (disabledDates.length > 0) {
          dateList.push({
            date: dateDay,
            day: date.getDate(),
            currMonth,
            currDay: dateDay === this.currDate ? true : false,
            disabled: true,
            actived: false,
            qty: activatedDay?.qty || 0,
            lunar: this.solar2lunar(date),
            status: disabledDates[0].status,
          })
        } else {
          dateList.push({
            date: dateDay,
            day: date.getDate(),
            currMonth,
            currDay: dateDay === this.currDate ? true : false,
            disabled: this.disabledDate(date) || dateDay === this.currDate,
            actived: activatedDay?.actived || false,
            qty: activatedDay?.qty || 0,
            lunar: this.solar2lunar(date),
            status: 0,
          })
        }
      }
      return dateList
    },
    // 日期选中、取消
    handleActived(index, val) {
      const dateList = this.dateList
      if (!dateList[index].disabled) {
        if (dateList[index].actived) {
          // alert('确定取消？')
          this.$set(dateList[index], 'actived', false)
          delete this.activedDate[val]
        } else {
          this.$set(dateList[index], 'actived', true)
          this.activedDate[val] = dateList[index]
        }
      }
    },
    // 禁用日期
    disabledDate(time) {
      const date = new Date(`${this.currDate}`).setHours(0, 0, 0, 0)
      return +time < +date || (this.getDiffDate && +time > this.getDiffDate)
    },
    dateFormatter(date, formatter = 'YYYY-MM-DD HH:mm:ss') {
      date = date ? new Date(date) : new Date()
      const Y = date.getFullYear() + '',
        M = date.getMonth() + 1,
        D = date.getDate(),
        H = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds()
      return formatter
        .replace(/YYYY|yyyy/g, Y)
        .replace(/YY|yy/g, Y.substr(2, 2))
        .replace(/MM/g, (M < 10 ? '0' : '') + M)
        .replace(/DD/g, (D < 10 ? '0' : '') + D)
        .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
        .replace(/mm/g, (m < 10 ? '0' : '') + m)
        .replace(/ss/g, (s < 10 ? '0' : '') + s)
    },
    // 阳历转农历
    solar2lunar(date) {
      return calendarFormatter.solar2lunar(date.getFullYear(), date.getMonth() + 1, date.getDate())
    },
    // 重置日历
    handleResetCalendar() {
      this.activedDate = {}
      this.handleMonthChange()
    },
    // 日历点击确定
    handleConfirmCalendar() {
      this.$emit('update', this.activedDate)
      var _this = this
      let dates = Object.keys(this.activedDate)
      let appointmentDateList = []
      dates.forEach((t) => {
        appointmentDateList.push(new Date(t + ' 00:00:00').getTime() / 1000)
      })
      if (appointmentDateList.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择日期！',
        })
        return
      }
      let map = {
        expertUserId: this.reservationInfo.id,
        appointmentDateList: appointmentDateList,
      }
      _this.ExpertAppointment.SaveAppointment(
        map,
        function (data) {
          _this.reservationDialog = false
          _this.$message({
            type: 'success',
            message: '预约已提交',
          })
          _this.reload()
        },
        function (error) {
          console.log(error)
          var jsonObj = JSON.parse(error.msg)
          if (jsonObj.code == '400') {
            _this.$message({
              type: 'error',
              message: jsonObj.msg,
            })
          }
        }
      )
    },
    infoBtn(item) {
      this.detaildrawer = true
      this.reservationInfo = item
    },
    Search() {
      this.pageIndex = 1
      this.GetList()
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.GetList()
    },
    GetList() {
      var _this = this
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.User.GetOrgPlatformExpertPages(
        item.startTime,
        item.endTime,
        _this.PageIndex,
        _this.searchForm.keyWord,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.dataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
:deep() .el-transfer-panel {
  width: auto;
}
.col {
  margin-bottom: 20px;
}
.note {
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  padding: 15px;
  height: 225px;
}
:deep() .el-dialog-box .el-dialog__body {
  padding-top: 0 !important;
}
.dialog-c {
  width: 95%;
  padding: 10px;
}

.note,
.dialog-c,
.noteInfo {
  .note-logo {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .note-name {
    font-weight: bold;
    margin-right: 10px;
  }
  .note-hos {
    margin-right: 10px;
  }

  .hos-desc {
    padding: 0 5px;
    border-radius: 4px;
    border: 0.1px solid #cad6f9;
    font-size: 12px;
    .note-l {
      margin-right: 2px;
    }
  }
  .note-2 {
    margin: 10px 0 15px 0;
    .note-desc {
      line-height: 1.2;
      position: relative;
      .desc {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        color: var(--base--Color);
        cursor: pointer;
      }
    }
  }
  .note-3 {
    font-weight: bold;
    background: #f6f6f8;
    border-radius: 4px;
    padding: 5px 10px;
    height: 40px;
    .note3-img {
    }
    .note3-shenfang {
      margin: 0 15px 0 10px;
    }
  }
  .note-4 {
    margin: 15px 0 0;
    .note4-logo {
      color: #02bf6f;
      margin-left: 5px;
    }
  }
}

.drawer-box {
  padding: 0 30px;
}

.drawer-box .top-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drawer-box .reviceUser-title {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.drawer-box .title {
  text-align: center;
  height: 50px;
  line-height: 50px;
}

.drawer-box .item-img {
  margin-right: 10px;
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  flex-wrap: wrap;
}

.item-img img {
  width: 118px;
  height: 118px;
}
</style>
<style scoped lang="scss">
.custom-calendar {
  width: 100%;
  height: 60vh;
  min-height: 60vh;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}
.calendar-wrapper {
  width: 100%;
  height: 100%;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
}
.calendar-header,
.calendar-week {
  width: 100%;
  height: 52px;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}
.calendar-week-list,
.calendar-date-list {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.calendar-header > div,
.calendar-week-list > div,
.calendar-date-list > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar-week-list > div,
.calendar-date-list > div {
  height: 100%;
  width: 14.2857%;
}
.calendar-date-wrapper {
  width: 100%;
  flex-grow: 1;
}
.calendar-date-list {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.calendar-date-list .calendar-date-list__item {
  height: auto;
  color: #a0a0a0;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
}
.calendar-date-list .calendar-date-list__item .date-status {
  font-size: 11px;
  width: 100%;
  position: absolute;
  right: 2px;
  bottom: 2px;
}
.calendar-date-list .calendar-date-list__item:not(.disabled) .date-status {
  color: #f88d00;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.calendar-date-list .calendar-date-list__item:nth-child(7n + 1) {
  border-left-width: 0;
}
.calendar-date-list .calendar-date-list__item .lunar {
  font-size: 8px;
}
.calendar-date-list .calendar-date-list__item.is-current-month:not(.disabled) {
  color: #000;
}
.calendar-date-list .calendar-date-list__item.is-current-month.is-current-day {
  // color: rgba(2, 191, 111, 0.8);
  color: var(--base--Color);
}
.calendar-date-list .calendar-date-list__item.isMe {
  background: rgba(230, 247, 241, 1) !important;
  color: #333;
}
.calendar-date-list .calendar-date-list__item.isOther {
  background: rgba(255, 239, 223, 1) !important;
  color: #333;
}
.calendar-date-list .calendar-date-list__item.isMe .isMeT {
  color: #00c773;
}
.calendar-date-list .calendar-date-list__item.isOther .isOtherT {
  color: #e1750d;
}
.calendar-date-list .calendar-date-list__item.is-current-month.is-current-day:not(.disabled):hover,
.calendar-date-list .calendar-date-list__item.is-current-month.is-current-day.is-actived {
  background: rgba(2, 191, 111, 0.8);
}
.calendar-date-list .calendar-date-list__item.disabled {
  background: rgba(238, 238, 238, 0.3);
}
.calendar-date-list .calendar-date-list__item.is-current-month:not(.disabled):hover,
.calendar-date-list .calendar-date-list__item.is-current-month.is-actived {
  background: rgba(2, 191, 111, 0.8);
  color: #fff;
}
.calendar-date-list .calendar-date-list__item:not(.disabled):hover .date-status,
.calendar-date-list .calendar-date-list__item.is-actived .date-status {
  color: #fff;
}
.calendar-date-list .calendar-date-list__item:not(.disabled):hover,
.calendar-date-list .calendar-date-list__item.is-actived {
  background: rgba(2, 191, 111, 0.8);
  color: #fff;
}
.calendar-date-list .calendar-date-list__item.is-current-month.disabled,
.calendar-date-list .calendar-date-list__item.disabled {
  cursor: not-allowed;
}
.calendar-footer {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px;
}
button.button--default {
  min-width: 60px;
  height: 38px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
  line-height: 1;
  padding: 4px 12px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
}
button.button--default + button.button--default {
  margin-left: 12px;
}
button.button--default.button--small {
  height: 34px;
}
button.button--default.button--mini {
  height: 30px;
}
button.button--default:hover {
  border-color: #3240ff;
  color: #3240ff;
}
button.button--default:active {
  background-color: #3240ff;
  border-color: #3240ff;
  color: #fff;
}
button.button--default.button--primary {
  background-color: #3240ff;
  border-color: #3240ff;
  color: #fff;
}
button.button--default.button--primary:hover {
  background-color: #5b66ff;
  border-color: #5b66ff;
}
button.button--default.button--primary:active {
  background-color: #2833cc;
  border-color: #2833cc;
}
</style>
